<template>
  <div class="content">
    <ApplicationNannyInputSalary
      v-model:fee-hour="applicationNanny.details.rate.hour"
      v-model:fee-month="applicationNanny.details.rate.month"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateExperienceWorking' }"
      :isDisabled="!isValid"
    />
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";

import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import ApplicationNannyInputSalary from "@/components/Application/Nanny/ApplicationNannyInputSalary.vue";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

export default {
  components: {
    ButtonPrimaryNext,
    ApplicationNannyInputSalary,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    const isValid = ref(false);

    return {
      applicationNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}

@media (max-width: 1200px) {
  .content {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 80px;
  }
}
</style>
